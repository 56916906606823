import React, { useState } from "react";
import "./PageBottom.css";
import plus from "../../img/plus.png";
import srca from "../../img/srca.png";
import sSrca from "../../img/sSrca.png";
import minus from "../../img/minus.png";
import stars5 from "../../img/stars5.png";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";

function PageBottom() {
	const initialFAQItems = [
		{
			id: 1,
			question: "Da li su psi non stop zatvoreni u boxu?",
			answer: "Psi su većinom dana pušteni u šetnji ili istrčavanju dok manji deo dana provode u boxovima. Noću su psi smešteni u boxovima do ranih jutarnjih sati i početka njihovog dana.",
		},
		{
			id: 2,
			question: "Koliko puta dnevno se hrane psi?",
			answer: "Psi se hrane dva puta dnevno. Jutarnji obrok se daje ujutru, dok se večernji obrok daje uveče. Ishrana je prilagođena individualnim potrebama svakog psa.",
		},
		{
			id: 3,
			question: "Da li je potrebno doneti vlastitu hranu za psa?",
			answer: "Nije potrebno donositi vlastitu hranu za psa. U našem centru pružamo kvalitetnu hranu prilagođenu potrebama svakog psa. Ako imate posebne zahteve ili preferencije, slobodno nam se obratite.",
		},
	];

	const [faqItems, setFaqItems] = useState(initialFAQItems);

	const handleToggleFAQ = (id) => {
		setFaqItems((prevItems) =>
			prevItems.map((item) =>
				item.id === id
					? {
							...item,
							isOpen: !item.isOpen,
							isAnswerVisible: !item.isOpen,
					  }
					: item
			)
		);
	};

	const handleHideAnswer = (id) => {
		setFaqItems((prevItems) =>
			prevItems.map((item) =>
				item.id === id
					? {
							...item,
							isOpen: false,
							isAnswerVisible: false,
					  }
					: item
			)
		);
	};

	return (
		<div className="pbContainer">
			<Fade duration={1000} left>
				<div className="pbFAQ">
					<div className="pbFAQHeader">Najčesće postavljana pitanja</div>
					<div className="pbFAQQ">
						{faqItems.map((item) => (
							<div key={item.id} className="faqItem">
								<div className="questionTogether">
									<div className="questionText">{item.question}</div>
									{item.isOpen ? (
										<img src={minus} alt="minus" onClick={() => handleHideAnswer(item.id)} />
									) : (
										<img src={plus} alt="plus" onClick={() => handleToggleFAQ(item.id)} />
									)}
								</div>
								<Fade duration={400} top>
									{item.isOpen ? (
										<>
											<img src={sSrca} alt="sSrca" className="srceImg" />
											<div className="answerSection">
												<div className="questionAnsw">{item.answer}</div>
											</div>
										</>
									) : (
										<img src={srca} alt="srca" className="srceImg" />
									)}
								</Fade>
							</div>
						))}
					</div>
					<NavLink to="/najcesca-pitanja" className="qnaLink">
						<button type="button">Sva pitanja</button>
					</NavLink>
				</div>
			</Fade>
			<Fade duration={1000} bottom>
				<div className="pbRevHeader">Rekli su o nama</div>
				<div className="pbReviews">
					<div className="revBubble">
						<img src={stars5} alt="5stars" />
						<div className="revText">
							Želeli bismo da izrazimo našu zahvalnost Wonderful Hotelu za pse na fantastičnoj usluzi koju su pružili našem
							psu, Beli. Njihovo osoblje se zaista istaklo u pružanju pažnje i nege našem ljubimcu. Smeštaj je besprekoran, sa
							obiljem mesta za igru i udobnost za pse. Redovno su nam slali ažuriranja o Belijevom boravku, uključujući i
							slike, što nas je uverilo da je srećan i dobro se zabavlja.
						</div>
						<div className="revName">Petrovići</div>
					</div>
					<div className="revBubble">
						<img src={stars5} alt="5stars" />
						<div className="revText">
							Naš ljubimac, Boki, nedavno je boravio u Wonderful Hotelu za pse, i iskreno, oduševljeni smo. Osoblje je bilo
							veoma ljubazno i profesionalno, i bili su izuzetno pažljivi prema. Smeštaj za pse je prostran, čist i udoban, sa
							svim potrebnim sadržajem. Takođe smo dobijali redovne ažuriranja o boravku, uključujući i slike,.
						</div>
						<div className="revName">Živkovići</div>
					</div>
				</div>
			</Fade>
		</div>
	);
}

export default PageBottom;
