import React from "react";
import "./PageKontakt.css";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import conntactPin from "../../img/pin2.png";
import contactPhone from "../../img/phone2.png";
import contactMail from "../../img/mail2.png";
import mapImg from "../../img/mapa.png";
import PageBottom from "../../components/PageBottom/PageBottom";
import { Fade } from "react-reveal";

function PageKontakt() {
	const openGoogleMaps = () => {
		window.open(
			"https://www.google.com/maps/place/WONDERFUL+PET+HOTEL/@44.9312681,20.2920213,14.25z/data=!4m14!1m7!3m6!1s0x475a5f91cf164717:0xbc49301165e63596!2sWONDERFUL+PET+HOTEL!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm!3m5!1s0x475a5f91cf164717:0xbc49301165e63596!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm?entry=ttu",
			"_blank"
		);
	};

	return (
		<>
			<div className="kontaktContainer">
				<Fade duration={1000}>
					<div className="kontaktHeader">
						<div className="headerText">kontaktirajte nas</div>
					</div>
					<div className="topContact">
						<Fade left>
							<div className="topLeftContact">
								<div className="headText">Wonderful pet centar - kontakt</div>
								<div className="descText">
									Ukoliko imate nedoumica, želite da proćaskamo ili tražite savet, budite slobodni da nas kontaktirate.
								</div>
								<div className="contacts">
									<div className="contactTogether">
										<img src={conntactPin} alt="gpsImg" />
										<div className="contactText">
											Put za Nove Banovce, <br /> 11080 Zemun
										</div>
									</div>
									<div className="contactTogether">
										<img src={contactPhone} alt="phoneImg" />
										<div className="contactText">
											<a href="tel:0600 376 279" className="linksText">
												+381 600 376 279
											</a>
										</div>
									</div>
									<div className="contactTogether">
										<img src={contactMail} alt="mailImg" />
										<div className="contactText">
											<a href="mailto:kontakt@wonderfulpetcare.rs" className="linksText">
												kontakt@wonderfulpetcare.rs
											</a>
										</div>
									</div>
								</div>
								<div className="header2">Gde se nalazimo?</div>
								<img src={mapImg} alt="mapImg" onClick={openGoogleMaps} className={"mapImage"} />
								<button onClick={openGoogleMaps}>{"Uvećajte mapu"}</button>
							</div>
						</Fade>
						<div className="topRightContact">
							<Fade duration={1000} right>
								<QuestionForm />
							</Fade>
							<Fade duration={1000} bottom>
								<GalerySmall />
							</Fade>
						</div>
					</div>
				</Fade>
			</div>
			<Fade duration={1000} bottom>
				<PageBottom />
			</Fade>
		</>
	);
}

export default PageKontakt;
