import React, { useState, useEffect } from "react";
import "./PageMain.css";
import PageBottom from "../../components/PageBottom/PageBottom";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import { NavLink } from "react-router-dom";
import sapaLeft from "../../img/mainSapa.png";
import box10 from "../../img/10.png";
import box5m2 from "../../img/5m2.png";
import box2 from "../../img/2.png";
import box320m2 from "../../img/320m2.png";
import sapaEmpty from "../../img/sapaEmpty.png";
import teamImg1 from "../../img/photoMain1.png";
import teamImg2 from "../../img/photoMain2.png";
import teamImg3 from "../../img/photoMain3.png";
import vozacBaki from "../../img/vozacBaki.png";
import arrowL from "../../img/arrowL.png";
import arrowR from "../../img/arrowR.png";
import galeryImg1 from "../../img/galery1.png";
import galeryImg2 from "../../img/galery2.png";
import galeryImg3 from "../../img/galery3.png";
import galeryImg4 from "../../img/gale1.jpg";
import galeryImg5 from "../../img/gale2.jpg";
import galeryImg6 from "../../img/gale3.jpg";
import galeryImg7 from "../../img/gal4.jpg";
import galeryImg8 from "../../img/gal5.jpg";
import galeryImg9 from "../../img/gal6.jpg";
import galeryImg10 from "../../img/gal7.jpg";
import galeryImg11 from "../../img/gal8.jpg";
import galeryImg12 from "../../img/gal9.jpg";
import galeryImg13 from "../../img/gal10.jpg";
import galeryImg14 from "../../img/gal11.jpg";
import galeryImg15 from "../../img/gal12.jpg";
import galeryImg16 from "../../img/gal13.jpg";
import galeryImg17 from "../../img/gal14.jpg";
import galeryImg18 from "../../img/gal15.jpg";
import { Fade } from "react-reveal";

function PageMain() {
	const images = [
		galeryImg1,
		galeryImg2,
		galeryImg4,
		galeryImg5,
		galeryImg6,
		galeryImg7,
		galeryImg8,
		galeryImg9,
		galeryImg10,
		galeryImg11,
		galeryImg12,
		galeryImg13,
		galeryImg14,
		galeryImg15,
		galeryImg16,
		galeryImg17,
		galeryImg18,
		galeryImg3,
	];
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 1000);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleClick = (direction) => {
		if (direction === "left") {
			setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
		} else if (direction === "right") {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}
	};

	const visibleImages = [
		images[currentIndex],
		images[(currentIndex + images.length - 1) % images.length],
		images[(currentIndex + 1) % images.length],
	];
	return (
		<>
			<div className="mainContainer">
				<Fade duration={1000}>
					<div className="mainHeader">
						<Fade duration={1000} left>
							<div className="mainHeaderText">
								Vaša Sreća <br /> je u njihovom repu
							</div>
							<div className="heroButtons">
								<NavLink to="/hotel-za-pse" className="heroButtonOne">
									<button>njušni prostor</button>
								</NavLink>
								<NavLink to="/kontakt" className="heroButtonTwo">
									<button>pošalji upit</button>
								</NavLink>
							</div>
						</Fade>
					</div>
				</Fade>
				<div className="mainTogether">
					<div className="mainLeft">
						<Fade duration={1000} left>
							<div className="leftBig">
								<img src={sapaLeft} alt="sapaLeft" />
							</div>
						</Fade>
						<div className="mainTopRight">
							<Fade duration={1000} top>
								<div className="textHeaderM">
									Dobrodošli u <span className="headerSpan">Wonderful</span> pet hotel
								</div>
								<div className="textNormalM">
									Vaša ljubav prema psima zaslužuje pansion u kome će se osećati kao kod kuće, <br /> a mi ćemo im
									obezbediti sve sto im je potrebno dok niste tu.
								</div>
							</Fade>
							<Fade duration={1000} right>
								<div className="fourboxes">
									<div className="topBoxes">
										<div className="boxItem">
											<img src={box10} alt="box10" />
											<div className="boxText">Vrhunski opremljenih boxova</div>
										</div>
										<div className="boxItem">
											<img src={box5m2} alt="box5m2" />
											<div className="boxText">Veličina boxa</div>
										</div>
									</div>
									<div className="botBoxes">
										<div className="boxItem">
											<img src={box2} alt="box2" />
											<div className="boxText">Poligona za dresuru</div>
										</div>
										<div className="boxItem">
											<img src={box320m2} alt="box320m2" />
											<div className="boxText">Prostora za igru</div>
										</div>
									</div>
								</div>
							</Fade>
							<Fade duration={1000} bottom>
								<div className="textNormalM">
									U našem
									<NavLink to="/hotel-za-pse" className="contact">
										<span className="linkSpanB"> Wonderful pet hotelu </span>
									</NavLink>
									pružamo najviši standard nege za vaše pse, bez obzira na njihove individualne potrebe. <br /> Naša
									posvećena ekipa će osigurati da vaš pas dobije personalizovanu pažnju i brigu koju zaslužuje. <br />
									<br /> Takođe, ponosno vam predstavljamo i uslugu
									<NavLink to="/pet-taxi" className="contact">
										<span className="linkSpanY"> Wonderful Pet Taxi </span>
									</NavLink>
									<br /> kako biste svom ljubimcu omogućili udoban i bezbedan prevoz kad god je to potrebno.
								</div>
								<div className="buttonsHBot">
									<NavLink to="/hotel-za-pse" className="botButtonOne">
										<button>pet hotel</button>
									</NavLink>
									<NavLink to="/pet-taxi" className="botButtonTwo">
										<button>pet taxi</button>
									</NavLink>
								</div>
							</Fade>
						</div>
					</div>
					<div className="mainRight">
						<Fade duration={1000} right>
							<QuestionForm />
						</Fade>
						<Fade duration={1000} bottom>
							<GalerySmall />
						</Fade>
					</div>
				</div>
				<Fade duration={1000} bottom>
					<div className="mainCenter">
						<div className="textHeaderM centerText">
							naši mali gosti su maženi, paženi
							<br />
							<span className="headerSpan">veoma zadovoljni </span>
						</div>
						<Fade duration={1000} right>
							<div className="galeryImages">
								<img className="arrowImage" src={arrowL} alt="arrowL" onClick={() => handleClick("left")} />
								<div className="galeryRotatingImages">
									{isSmallScreen
										? visibleImages.slice(0, 1).map((image, index) => (
												<div className="imgElement" key={index}>
													<img src={image} alt={`galeryImg${currentIndex + index + 1}`} />
												</div>
										  ))
										: visibleImages.map((image, index) => (
												<div className="imgElement" key={index}>
													<img src={image} alt={`galeryImg${currentIndex + index + 1}`} />
												</div>
										  ))}
								</div>
								<img className="arrowImage" src={arrowR} alt="arrowR" onClick={() => handleClick("right")} />
							</div>
						</Fade>
					</div>
				</Fade>
				<Fade duration={1000} bottom>
					<div className="mainBottom">
						<div className="mainBottomL">
							<Fade duration={1000} top>
								<div className="imgSapa">
									<img src={sapaEmpty} alt="sapa" />
								</div>
							</Fade>
							<Fade duration={1000} left>
								<div className="textHeaderM">Vrhunski ambijent i stručni tim</div>
								<div className="textNormalM">
									U našem timu, imamo troje posvećenih članova, svaki sa svojim jedinstvenim veštinama i stručnostima u
									pružanju najbolje moguće nege za pse. Bez obzira na to šta vašem psu treba - bilo da su u pitanju
									redovne šetnje, posebna pažnja ili jednostavno topao kutak za opuštanje, možete se osloniti na nas da
									pružimo sve što je potrebno vašem četvoronožnom prijatelju. <br />
									<br /> Za nas, rad sa psima je više od posla - to je naša strast i privilegija. Naš cilj je osigurati da
									svaki pas dobije pažnju i brigu kakvu zaslužuje. Kada nam se obratite, možete biti sigurni da vaš pas će
									biti u rukama strastvenog tima koji brine o psima kao da su vlastiti. Vaša potpuna sigurnost i sreća
									vašeg ljubimca su nam na prvom mestu.
								</div>
							</Fade>
							<Fade duration={1000} right>
								<div className="teamImgs">
									<div className="imgTogether">
										<img src={teamImg1} alt="teamImg1" />
										<div className="teamNameText">Miloš</div>
									</div>
									<div className="imgTogether">
										<img src={teamImg2} alt="teamImg2" />
										<div className="teamNameText">Ivana</div>
									</div>
									<div className="imgTogether">
										<img src={teamImg3} alt="teamImg3" />
										<div className="teamNameText">Marko</div>
									</div>
								</div>
							</Fade>
							<Fade duration={1000} bottom>
								<div className="buttonsHBot lowerButtons">
									<NavLink to="/hotel-za-pse" className="botButtonOne">
										<button>o nama</button>
									</NavLink>
									<NavLink to="/kontakt" className="lowerTwo">
										<button>pošaljite upit</button>
									</NavLink>
								</div>
							</Fade>
						</div>
						<Fade duration={1000} right>
							<div className="mainBottomR">
								<div className="imgVozacBaki">
									<img src={vozacBaki} alt="vozacBaki" />
								</div>
								<div className="textVozacBaki">Vozač Baki</div>
							</div>
						</Fade>
					</div>
				</Fade>
			</div>
			<Fade duration={1000} bottom>
				<PageBottom />
			</Fade>
		</>
	);
}

export default PageMain;
