import React from "react";
import "./GalerySmall.css";
import { NavLink } from "react-router-dom";

function GalerySmall() {
  return (
    <div className="galeryContainer">
      <NavLink
        to="/galerija"
        className="galeryLink"
        style={{ textDecoration: "none" }}
      >
        <div className="headerImgGalery">Foto galerija</div>
        <button type="button">Pogledaj sve</button>
      </NavLink>
    </div>
  );
}

export default GalerySmall;
