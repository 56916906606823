import React, { useState, useEffect } from "react";
import "./NavbarXS.css";
import headerLogo from "../../img/headerLogo.png";
import { NavLink, useLocation } from "react-router-dom";
import { Fade } from "react-reveal";

const NavbarXS = () => {
	const location = useLocation();
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [isSticky, setIsSticky] = useState(false);

	const toggleDrawer = () => {
		setDrawerOpen(!isDrawerOpen);
	};

	const activeStyle = (path) => {
		return location.pathname === path ? "selected" : "";
	};
	useEffect(() => {
		const handleScroll = () => {
			setIsSticky(window.scrollY > 0);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className={`drawer-container ${isSticky ? "sticky" : ""}`} onClick={toggleDrawer}>
			<Fade duration={1000} top>
				<div className="blue-line">
					<div className="imgLeft">☰</div>
					<div className="imgRight">
						<img src={headerLogo} alt="headerLogo" />
					</div>
				</div>
			</Fade>

			{isDrawerOpen && (
				<div className="drawer-overlay" onClick={toggleDrawer}>
					<Fade duration={1000} left>
						<div className="drawer">
							<div className="drawer-logo">
								<NavLink to="/naslovna">
									<img src={headerLogo} alt="Logo" />
								</NavLink>
							</div>
							<NavLink to="/naslovna" className={`drawer-item ${activeStyle("/naslovna")}`} onClick={toggleDrawer}>
								NASLOVNA
							</NavLink>
							<NavLink to="/hotel-za-pse" className={`drawer-item ${activeStyle("/hotel-za-pse")}`} onClick={toggleDrawer}>
								HOTEL ZA PSE
							</NavLink>
							<NavLink to="/pet-taxi" className={`drawer-item ${activeStyle("/pet-taxi")}`} onClick={toggleDrawer}>
								PET TAXI
							</NavLink>
							<NavLink to="/galerija" className={`drawer-item ${activeStyle("/galerija")}`} onClick={toggleDrawer}>
								GALERIJA
							</NavLink>
							<NavLink to="/cenovnik" className={`drawer-item ${activeStyle("/cenovnik")}`} onClick={toggleDrawer}>
								CENOVNIK
							</NavLink>
							<NavLink to="/najcesca-pitanja" className={`drawer-item ${activeStyle("/najcesca-pitanja")}`} onClick={toggleDrawer}>
								NAJČEŠĆA PITANJA
							</NavLink>
							<NavLink to="/kontakt" className={`drawer-item ${activeStyle("/kontakt")}`} onClick={toggleDrawer}>
								KONTAKT
							</NavLink>
						</div>
					</Fade>
				</div>
			)}
		</div>
	);
};

export default NavbarXS;
