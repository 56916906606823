import React, { useState } from "react";
import PageBottom from "../../components/PageBottom/PageBottom";
import Lightbox from "yet-another-react-lightbox";
import "./PageGalerija.css";
import "yet-another-react-lightbox/styles.css";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import gal1 from "../../img/gal1.png";
import gal2 from "../../img/gal2.png";
import gal3 from "../../img/gal3.png";
import gal4 from "../../img/gal4.jpg";
import gal5 from "../../img/gal5.jpg";
import gal6 from "../../img/gal6.jpg";
import gale1 from "../../img/gale1.jpg";
import gale2 from "../../img/gale2.jpg";
import gale3 from "../../img/gale3.jpg";
import gale4 from "../../img/gal4.jpg";
import gale6 from "../../img/gal6.jpg";
import gale7 from "../../img/gal7.jpg";
import gale8 from "../../img/gal8.jpg";
import gale9 from "../../img/gal9.jpg";
import gale10 from "../../img/gal10.jpg";
import gale11 from "../../img/gal11.jpg";
import gale12 from "../../img/gal12.jpg";
import gale13 from "../../img/gal13.jpg";
import gale14 from "../../img/gal14.jpg";
import gale15 from "../../img/gal15.jpg";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";

function PageGalerija() {
	const [index, setIndex] = useState(-1);

	const images = [
		gal1,
		gal2,
		gal3,
		gal4,
		gal5,
		gal6,
		gale1,
		gale2,
		gale3,
		gale4,
		gale6,
		gale7,
		gale8,
		gale9,
		gale10,
		gale11,
		gale12,
		gale13,
		gale14,
		gale15,
	];

	return (
		<>
			<div className="galerijaContainer">
				<Fade duration={1000}>
					<div className="galerijaHeader">
						<div className="galerijaHeaderText">Galerija</div>
					</div>
				</Fade>
			</div>
			<div className="galerijaTogether">
				<div className="galerijaLeft">
					<Fade duration={1000} left>
						<div className="headText">Galerija</div>
						<div className="descText">
							Aftmosfera u Pet hotelu Wonderful kao i osećaj vaših ljubimaca možda će se najbolje dočarati fotografijama.
							Prikupljamo i kolekciju naših cenjenih krznenih gostiju za fun album na Instagramu pa ukoliko ste ih sami
							napravili možete ih poslati na
							<NavLink to="/kontakt" className="contact" style={{ textDecoration: "none" }}>
								<span className="linkSpanT"> našu email adresu. </span>
							</NavLink>
						</div>
					</Fade>
					<Fade duration={1000} bottom>
						<div className="galerijaImages">
							<img src={gal1} alt="gal1" onClick={() => setIndex(0)} />
							<img src={gal2} alt="gal2" onClick={() => setIndex(1)} />
							<img src={gal3} alt="gal3" onClick={() => setIndex(2)} />
						</div>
						<div className="galerijaImages">
							<img src={gal4} alt="gal4" onClick={() => setIndex(3)} style={{ borderRadius: "15px" }} />
							<img src={gale10} alt="gale10" onClick={() => setIndex(4)} style={{ borderRadius: "15px" }} />
							<img src={gal6} alt="gal6" onClick={() => setIndex(5)} style={{ borderRadius: "15px" }} />
						</div>
					</Fade>
				</div>
				<div className="galerijaRight">
					<Fade duration={1000} right>
						<QuestionForm />
					</Fade>
					<Fade duration={1000} bottom>
						<GalerySmall />
					</Fade>
				</div>
			</div>

			<Lightbox
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				slides={images.map((src) => ({ src }))}
				plugins={[Slideshow, Zoom, Thumbnails, Download]}
			/>
			<PageBottom />
		</>
	);
}

export default PageGalerija;
