import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import React, { useState, useEffect } from "react";
import PageMain from "./pages/PageMain/PageMain";
import PageHotel from "./pages/PageHotel/PageHotel";
import PageTaxi from "./pages/PageTaxi/PageTaxi";
import Navbar from "./components/Navbar/Navbar";
import PageCenovnik from "./pages/PageCenovnik/PageCenovnik";
import PageGalerija from "./pages/PageGalerija/PageGalerija";
import PageQnA from "./pages/PageQnA/PageQnA";
import PageKontakt from "./pages/PageKontakt/PageKontakt";
import Footer from "./components/Footer/Footer";
import StickyBoxes from "./components/StickyBoxes/StickyBoxes";
import ScrollToTop from "./scrollToTop";
import NavbarXS from "./components/NavbarXS/NavbarXS";

function App() {
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 1000);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Router>
			<ScrollToTop />
			<div className="App">
				{isSmallScreen ? <NavbarXS /> : <Navbar />}
				<StickyBoxes />
				<Routes>
					<Route path="/naslovna" element={<PageMain />} />
					<Route path="/hotel-za-pse" element={<PageHotel />} />
					<Route path="/pet-taxi" element={<PageTaxi />} />
					<Route path="/galerija" element={<PageGalerija />} />
					<Route path="/cenovnik" element={<PageCenovnik />} />
					<Route path="/najcesca-pitanja" element={<PageQnA />} />
					<Route path="/kontakt" element={<PageKontakt />} />
					{/* Catch-all route for undefined paths */}
					<Route path="*" element={<Navigate to="/naslovna" />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
