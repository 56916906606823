import React from "react";
import PageBottom from "../../components/PageBottom/PageBottom";
import "./PageCenovnik.css";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";

function PageCenovnik() {
	return (
		<>
			<div className="cenoContainer">
				<Fade duration={1000}>
					<div className="cenoHeader">
						<div className="cenoHeaderText">Cenovnik</div>
					</div>
				</Fade>

				<div className="ceneTogether">
					<Fade duration={1000} left>
						<div className="ceneLeft">
							<div className="ceneHeader">Wonderful - nas cenovnik</div>
							<div className="ceneText">
								Upoznajte se sa cenama i paketima u našem hotelu. <br /> Za duži boravak ili boravak više ljubimaca
								istovremeno obezbeđujemo i dodatni popust o čemu se možete interesovati putem našeg kontakt telefona ili
								emaila.
								<br />
								<br />
								<div className="packPare">
									<span className="spanClass">Specijalna ponuda od 01.10 do 01.04. ! </span>
								</div>
							</div>
							<div className="cenePacks">
								<div className="cenePack">
									<div className="packHeader">Standard Paket</div>
									<div className="packText textBlue">Smeštaj i nadzor</div>
									<div className="packText textBlue">Vreme za igru</div>
									<div className="packText textBlue">Istrčavanje i vežbanje</div>
									<div className="packText textGrey">Hrana</div>
									<div className="packText textGrey">Pet taxi*</div>
									<div className="packPare">
										<span class="spanClass">
											<s> 1700 </s>
											1300 <span class="superscript">00</span>
											<span class="rsdpodanu">rsd po danu</span>
										</span>
									</div>
								</div>
								<div className="cenePack">
									<div className="packHeader">Plus paket</div>
									<div className="packText textBlue">Smeštaj i nadzor</div>
									<div className="packText textBlue">Vreme za igru</div>
									<div className="packText textBlue">Istrčavanje i vežbanje</div>
									<div className="packText textBlue">Hrana</div>
									<div className="packText textGrey">Pet taxi*</div>
									<div className="packPare">
										<span class="spanClass">
											<s> 2000 </s>
											1500 <span class="superscript">00</span>
											<span class="rsdpodanu">rsd po danu</span>
										</span>
									</div>
								</div>
							</div>
							<div className="ceneText">
								*Ukoliko koristite uslugu naseg Wonderful Pet Hotela duže od 7 dana, usluga
								<NavLink to="/pet-taxi" className="taxiLink">
									<span className="linkSpan"> Pet Taxi </span>
								</NavLink>
								odnosno transporta vašeg ljubimca na teritoriji Beograda od vašeg doma do Wonderful Pet Hotela i nazad je
								gratis.
							</div>
							<div className="cenePack">
								<div className="packHeader">Pet taxi</div>
								<div className="packPare">
									<span class="spanClass">
										<s> 70</s>
										<span class="superscript">00</span>
										<span class="rsdpodanu">rsd po povratnom kilometru</span>
									</span>
								</div>
								<br />
								<div className="packPare">
									<span className="spanClass">GRATIS</span>
								</div>
							</div>
							<div className="ceneText">
								Usluga "Pet Taxi" u Wonderful Pet Hotelu pruža vam brz i pouzdan transport vaših ljubimaca od tačke A do
								tacke B, <br /> sa naplatom zasnovanom na povratnim kilometrima.
								<NavLink to="/pet-taxi" className="taxiLink">
									<span className="linkSpan">Saznaj više</span>
								</NavLink>
							</div>
						</div>
					</Fade>
					<div className="ceneRight">
						<Fade duration={1000} right>
							<QuestionForm />
						</Fade>
						<Fade duration={1000} bottom>
							<GalerySmall />
						</Fade>
					</div>
				</div>
			</div>
			<Fade duration={1000} bottom>
				<PageBottom />
			</Fade>
		</>
	);
}

export default PageCenovnik;
