import React from "react";
import "./PageTaxi.css";
import PageBottom from "../../components/PageBottom/PageBottom";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import sapa1 from "../../img/sapa1.png";
import sapa2 from "../../img/sapa2.png";
import sapa3 from "../../img/sapa3.png";
import sapa4 from "../../img/sapa4.png";
import sapa5 from "../../img/sapa5.png";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";

function PageTaxi() {
  return (
    <>
      <div className="taxiContainer">
        <Fade duration={1000}>
          <div className="taxiHeader">
            <div className="taxiHeaderText">Pet taxi</div>
          </div>
        </Fade>
        <div className="taxiTogether">
          <div className="taxiLeft">
            <Fade duration={1000} left>
              <div className="textHeader">Wonderful pet hotel - pet taxi</div>
              <div className="textNormalT">
                Usluga "Pet Taxi" u Wonderful Pet Hotelu pruža vam brz i pouzdan
                transport vaših ljubimaca specijalizovanim voyilima od tačke A
                do tačke B, sa naplatom zasnovanom na povratnim kilometrima. Bez
                obzira da li je reč o poseti veterinaru, groomeru ili
                jednostavno o vožnji do Wonderful Pet Hotela, naša usluga Pet
                Taxi vam omogućava da brzo i lako organizujete transport vašeg
                ljubimca po pristupačnim cenama. Vaša udobnost i sigurnost vašeg
                ljubimca su nam prioritet, uz jasno i transparentno
                obračunavanje na osnovu pređene kilometraže. <br /> <br />
                <NavLink
                  to="/kontakt"
                  className="contact"
                  style={{ textDecoration: "none" }}
                >
                  <span className="linkSpanT"> Kontaktirajte nas </span>
                </NavLink>{" "}
                kako bismo organizovali udoban i siguran prevoz za vašeg
                četvoronožnog prijatelja!
              </div>
            </Fade>
            <Fade duration={1000} bottom>
              <div className="textItemT">
                <img src={sapa1} alt="sapa1" />
                <div className="itemTextTogetherT">
                  <div className="itemHeaderT">
                    Siguran i prijatan transport
                  </div>
                  <div className="itemText">
                    Nudimo siguran i prijatan transport vasih ljubimaca.
                    Prilagođjen njihovima potrebama i udobnosti tokom celog
                    putovanja.
                  </div>
                </div>
              </div>
              <div className="textItemT">
                <img src={sapa2} alt="sapa2" />
                <div className="itemTextTogetherT">
                  <div className="itemHeaderT">
                    Pokrivenost na celoj teritoriji beograda
                  </div>
                  <div className="itemText">
                    Za naše goste na teritoriji Beograda, usluga "Pet taxi" je
                    uključena u cenu ako se ljubimci dovoze ili odvoze iz
                    Wonderful Pet Hotela, čineći vaše iskustvo jos
                    jednostavnijim.
                  </div>
                </div>
              </div>
              <div className="textItemT">
                <img src={sapa3} alt="sapa3" />
                <div className="itemTextTogetherT">
                  <div className="itemHeaderT">Stručno osoblje</div>
                  <div className="itemText">
                    Naše vozače odlikuje ljubaznost i posvećenost dobrobiti
                    vašeg ljubimca tokom celog putovanja. Bezbednost i udobnost
                    su naša glavna briga.
                  </div>
                </div>
              </div>
              <div className="textItemT">
                <img src={sapa4} alt="sapa4" />
                <div className="itemTextTogetherT">
                  <div className="itemHeaderT">
                    Prilagodljivost i dostupnost
                  </div>
                  <div className="itemText">
                    Zdravlje i dobrobit vasih ljubimaca su nam prioritet. Imamo
                    tim patronažnih veterinara koji redovno prate i brinu se o
                    svim našim gostima.
                  </div>
                </div>
              </div>
              <div className="textItemT">
                <img src={sapa5} alt="sapa5" />
                <div className="itemTextTogetherT">
                  <div className="itemHeaderT">
                    Povežite se sa wonderful pet centrom
                  </div>
                  <div className="itemText">
                    Ukoliko koristite uslugu transporta u okviru beograda i
                    Wpnderful Pet Hotela, povezujemo se sa našim centrom kako
                    bismo osigurali besprekorno iskustvo za vaše ljubimce.
                  </div>
                </div>
              </div>
              <div className="textNormalT">
                Vasi ljubimci zaslužuju samo najbolje, i mi smo ovde da im to
                pružimo.
                <NavLink
                  to="/kontakt"
                  className="contact"
                  style={{ textDecoration: "none" }}
                >
                  <span className="linkSpanT"> Kontaktirajte nas </span>
                </NavLink>
                danas kako bismo organizovali siguran i udoban transport.
              </div>
            </Fade>
          </div>
          <div className="taxiRight">
            <Fade duration={1000} right>
              <QuestionForm />
            </Fade>
            <Fade duration={1000} bottom>
              <GalerySmall />
            </Fade>
          </div>
        </div>
      </div>
      <Fade duration={1000} bottom>
        <PageBottom />
      </Fade>
    </>
  );
}

export default PageTaxi;
