import React, { useState } from "react";
import "./PageQnA.css";
import plus from "../../img/plus.png";
import srca from "../../img/srca.png";
import sSrca from "../../img/sSrca.png";
import minus from "../../img/minus.png";
import stars5 from "../../img/stars5.png";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import { Fade } from "react-reveal";

function PageQnA() {
	const initialFAQItems = [
		{
			id: 1,
			question: "Da li su psi non stop zatvoreni u boxu?",
			answer: "Psi su većinom dana pušteni u šetnji ili istrčavanju dok manji deo dana provode u boxovima. Noću su psi smešteni u boxovima do ranih jutarnjih sati i početka njihovog dana.",
		},
		{
			id: 2,
			question: "Koliko puta dnevno se hrane psi?",
			answer: "Psi se hrane dva puta dnevno. Jutarnji obrok se daje ujutru, dok se večernji obrok daje uveče. Ishrana je prilagođena individualnim potrebama svakog psa.",
		},
		{
			id: 3,
			question: "Da li je potrebno doneti vlastitu hranu za psa?",
			answer: "Nije potrebno donositi vlastitu hranu za psa. U našem centru pružamo kvalitetnu hranu prilagođenu potrebama svakog psa. Ako imate posebne zahteve ili preferencije, slobodno nam se obratite.",
		},
		{
			id: 4,
			question: "Koliko često se setaju psi i da li je to uključeno u cenu?",
			answer: "Psi se šetaju nekoliko puta dnevno, a šetnje su uključene u cenu smeštaja.",
		},
		{
			id: 5,
			question: "Da li imate redovne posete veterinara?",
			answer: "Da, redovne posete veterinara su deo naše usluge kako bismo osigurali dobro zdravlje vaših ljubimaca.",
		},
		{
			id: 6,
			question: "Kako se reaguje u situacijama hitnih slučajeva ili bolesti pasa?",
			answer: "U slučaju hitnih situacija ili bolesti pasa, odmah reagujemo i pružamo adekvatnu veterinarsku negu.",
		},
		{
			id: 7,
			question: "Da li postoje kamere u pansionu i da li je moguće praćenje pasa preko istih?",
			answer: "Postoje kamere u našem pansionu koje omogućavaju praćenje pasa u realnom vremenu kako biste imali mirnu svest o njihovom boravku.",
		},
		{
			id: 8,
			question: "Kakva je oprema dostupna za igru pasa?",
			answer: "Naš pansion nudi raznovrsnu opremu za igru pasa, uključujući igračke, loptice i prostor za druženje s drugim psima.",
		},
		{
			id: 9,
			question: "Kako se postupa s posebnim dijetetskim potrebama pasa?",
			answer: "Ako vaš pas ima posebne dijetetske potrebe, slobodno nam se obratite. Pružamo personaliziranu ishranu kako bismo zadovoljili specifične potrebe svakog psa.",
		},
	];

	const [faqItems, setFaqItems] = useState(initialFAQItems);

	const handleToggleFAQ = (id) => {
		setFaqItems((prevItems) =>
			prevItems.map((item) =>
				item.id === id
					? {
							...item,
							isOpen: !item.isOpen,
							isAnswerVisible: !item.isOpen,
					  }
					: item
			)
		);
	};

	const handleHideAnswer = (id) => {
		setFaqItems((prevItems) =>
			prevItems.map((item) =>
				item.id === id
					? {
							...item,
							isOpen: false,
							isAnswerVisible: false,
					  }
					: item
			)
		);
	};

	return (
		<div className="qnaContainer">
			<Fade duration={1000}>
				<div className="qnaHeader">
					<div className="qnaHeaderText">Najšesća pitanja</div>
				</div>
			</Fade>
			<div className="qnaTogether">
				<Fade duration={1000} left>
					<div className="qnaLeft">
						<div className="qnaFAQ">
							<div className="qnaFAQHeader">Najšešće postavljana pitanja</div>
							<div className="qnaFAQQ">
								{faqItems.map((item) => (
									<div key={item.id} className="faqItem">
										<div className="QquestionTogether">
											<div className="questionText">{item.question}</div>
											{item.isOpen ? (
												<img src={minus} alt="minus" onClick={() => handleHideAnswer(item.id)} />
											) : (
												<img src={plus} alt="plus" onClick={() => handleToggleFAQ(item.id)} />
											)}
										</div>
										<Fade duration={400} top>
											{item.isOpen ? (
												<>
													<img src={sSrca} alt="sSrca" className="srceImg" />
													<div className="answerSection">
														<div className="questionAnsw">{item.answer}</div>
													</div>
												</>
											) : (
												<img src={srca} alt="srca" className="srceImg" />
											)}
										</Fade>
									</div>
								))}
							</div>
						</div>
					</div>
				</Fade>
				<div className="qnaRight">
					<Fade duration={1000} right>
						<QuestionForm />
					</Fade>
					<Fade duration={1000} bottom>
						<GalerySmall />
					</Fade>
				</div>
			</div>
			<Fade duration={1000} bottom>
				<div className="botQna">
					<div className="qnaRevHeader">Rekli su o nama</div>
					<div className="qnaReviews">
						<div className="QrevBubble">
							<img src={stars5} alt="5stars" />
							<div className="QrevText">
								Želeli bismo da izrazimo našu zahvalnost Wonderful Hotelu za pse na fantastičnoj usluzi koju su pružili
								našem psu, Beli. Njihovo osoblje se zaista istaklo u pružanju pažnje i nege našem ljubimcu. Smeštaj je
								besprekoran, sa obiljem mesta za igru i udobnost za pse. Redovno su nam slali ažuriranja o Belijevom
								boravku, uključujući i slike, što nas je uverilo da je srećan i dobro se zabavlja.
							</div>
							<div className="QrevName">Petrovići</div>
						</div>
						<div className="QrevBubble">
							<img src={stars5} alt="5stars" />
							<div className="QrevText">
								Naš ljubimac, Boki, nedavno je boravio u Wonderful Hotelu za pse, i iskreno, oduševljeni smo. Osoblje je
								bilo veoma ljubazno i profesionalno, i bili su izuzetno pažljivi prema. Smeštaj za pse je prostran, čist i
								udoban, sa svim potrebnim sadržajem. Takođe smo dobijali redovne ažuriranja o boravku, uključujući i slike,.
							</div>
							<div className="QrevName">Živkovići</div>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
}

export default PageQnA;
