// Footer.js
import React, { useEffect, useState } from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import navPin from "../../img/navPin.png";
import iconMail from "../../img/iconMail.png";
import iconPhone from "../../img/iconPhone.png";
import footerLogo from "../../img/footerLogo.png";
import { Fade } from "react-reveal";

function Footer() {
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 950);
		};

		// Initial check and set
		handleResize();

		// Event listener for window resize
		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const renderLineTop = () => {
		if (isSmallScreen) {
			return (
				<div className="footerLine lineTop ">
					<div className="footerTop">
						<a
							href="https://www.google.com/maps/place/WONDERFUL+PET+HOTEL/@44.9312681,20.2920213,14.25z/data=!4m14!1m7!3m6!1s0x475a5f91cf164717:0xbc49301165e63596!2sWONDERFUL+PET+HOTEL!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm!3m5!1s0x475a5f91cf164717:0xbc49301165e63596!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm?entry=ttu"
							className="footerLinkText"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={navPin} alt="navPin" />
						</a>
					</div>
					<div className="footerTop">
						<a href="tel:0600 376 279">
							<img src={iconPhone} alt="iconPhone" />
						</a>
					</div>
					<div className="footerTop">
						<a href="mailto:kontakt@wonderfulpetcare.rs">
							<img src={iconMail} alt="iconMail" />
						</a>
					</div>
				</div>
			);
		}

		return (
			<div className="footerLine lineTop">
				<div className="footerTop">
					<a
						href="https://www.google.com/maps/place/WONDERFUL+PET+HOTEL/@44.9312681,20.2920213,14.25z/data=!4m14!1m7!3m6!1s0x475a5f91cf164717:0xbc49301165e63596!2sWONDERFUL+PET+HOTEL!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm!3m5!1s0x475a5f91cf164717:0xbc49301165e63596!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm?entry=ttu"
						className="footerLinkText"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={navPin} alt="navPin" />
					</a>
					<a
						href="https://www.google.com/maps/place/WONDERFUL+PET+HOTEL/@44.9312681,20.2920213,14.25z/data=!4m14!1m7!3m6!1s0x475a5f91cf164717:0xbc49301165e63596!2sWONDERFUL+PET+HOTEL!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm!3m5!1s0x475a5f91cf164717:0xbc49301165e63596!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm?entry=ttu"
						className="footerLinkText"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span>Put za Nove Banovce, 11080 Zemun</span>
					</a>
				</div>
				<div className="footerTop">
					<a href="tel:0600 376 279">
						<img src={iconPhone} alt="iconPhone" />
					</a>
					<a href="tel:0600 376 279" className="footerLinkText">
						<span className="footerLinkText">+381 600 376 279</span>
					</a>
				</div>
				<div className="footerTop">
					<a href="mailto:kontakt@wonderfulpetcare.rs">
						<img src={iconMail} alt="iconMail" />
					</a>
					<a href="mailto:kontakt@wonderfulpetcare.rs" className="footerLinkText">
						<span>kontakt@wonderfulpetcare.rs</span>
					</a>
				</div>
			</div>
		);
	};

	return (
		<div className="footer">
			{renderLineTop()}
			<div className="footerLine lineMid">
				<Fade duration={1000} left>
					<div className="midSection">
						<div className="leftLogo">
							<img src={footerLogo} alt="footer logo" />
							<div className="separatorLine"></div>
						</div>
						<div className="leftText">
							U našem Wonderful Pet hotelu pružamo najviši standard nege za vaše pse, bez obzira na njihove individualne
							potrebe.
							<br />
							<br />
							Naša posvećena ekipa će osigurati da vaš pas dobije personalizovanu pažnju i brigu koju zaslužuje.
						</div>
					</div>
				</Fade>
				<Fade duration={1000} bottom>
					<div className="midSection">
						<div className="footerHeader">Brzi linkovi i navigacija</div>
						<div className="separatorLine"></div>
						<NavLink to="/naslovna" className="footerLink">
							Naslovna
						</NavLink>
						<NavLink to="/hotel-za-pse" className="footerLink">
							Hotel za pse
						</NavLink>
						<NavLink to="/pet-taxi" className="footerLink">
							Pet taxi
						</NavLink>
						<NavLink to="/galerija" className="footerLink">
							Galerija
						</NavLink>
						<NavLink to="/najcesca-pitanja" className="footerLink">
							Najčešća pitanja
						</NavLink>
						<NavLink to="/cenovnik" className="footerLink">
							Cenovnik
						</NavLink>
						<NavLink to="/kontakt" className="footerLink">
							Kontakt
						</NavLink>
					</div>
				</Fade>
				<Fade duration={1000} right>
					<div className="midSection">
						<div className="footerHeader">Naši partneri</div>
						<div className="separatorLine"></div>
						<a href="https://www.instagram.com/ljutazirafa/" className="footerLink" target="_blank" rel="noopener noreferrer">
							Veterinar - Ljuta Zirafa (Batajnica)
						</a>
						<a href="https://www.instagram.com/cocogroom/" className="footerLink" target="_blank" rel="noopener noreferrer">
							Grooming salon - Coco grooming (Batajnica)
						</a>
						{/* <a href="https://www.google.com" className="footerLink">
							Neka firma za igračke
						</a>
						<a href="https://www.google.com" className="footerLink">
							Neki brend
						</a> */}
					</div>
				</Fade>
			</div>
			{isSmallScreen ? (
				<div className="footerLine lineBot">
					<div className="botSection">Copyright @ 2023. Hotel za pse Wonderful. Sva prava zadržana</div>
					<a href="https://www.google.com" className="botSection link">
						Izrada sajta
					</a>
				</div>
			) : (
				<div className="footerLine lineBot">
					<div className="botSection">Copyright @ 2023. Hotel za pse Wonderful.</div>
					<div className="botSection">Sva prava zadržana</div>
					<a href="https://www.google.com" className="botSection link">
						Uslovi korišćenja
					</a>
					<a href="https://www.google.com" className="botSection link">
						Politika privatnosti
					</a>
					<a
						href="https://green-adv.rs/izrada-web-sajtova-i-prezentacija/"
						className="botSection link"
						target="_blank"
						rel="noopener noreferrer"
					>
						Izrada sajta
					</a>
				</div>
			)}
		</div>
	);
}

export default Footer;
