import React from "react";
import QuestionForm from "../../components/QuestionForm/QuestionForm";
import GalerySmall from "../../components/GalerySmall/GalerySmall";
import "./PageHotel.css";
import doggie1 from "../../img/doggie1.png";
import doggie2 from "../../img/doggie2.png";
import doggie3 from "../../img/doggie3.png";
import doggie4 from "../../img/doggie4.png";
import doggie5 from "../../img/doggie5.png";
import PageBottom from "../../components/PageBottom/PageBottom";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";

function PageHotel() {
  return (
    <>
      <div className="hotelContainer">
        <Fade duration={1000}>
          <div className="hotelHeader">
            <div className="hotelHeaderText">Hotel za pse</div>
          </div>
        </Fade>
        <div className="hotelTogether">
          <div className="hotelLeft">
            <Fade duration={1000} left>
              <div className="textHeader">Wonderful - hotel za pse</div>
              <div className="textNormal">
                Dobrodošli na stranicu "O nama" Wonderful Pet Hotela, mesta za
                pružanje vrhunskih usluga pansiona za pse u Zemunu, na Putu za
                Nove Banovce. Naša misija je da obezbedimo vašim četvoronožnim
                prijateljima udoban, siguran i zabavan boravak dok ste vi
                odsutni. Naš hotel se ponosi vrhunskim sadržajima i
                profesionalnom uslugom koja će ispuniti sve potrebe vaših
                ljubimaca.
              </div>
            </Fade>
            <Fade duration={1000} bottom>
              <div className="headerMini">Evo šta nas izdvaja od drugih</div>
              <div className="textItem">
                <img src={doggie2} alt="doggie1" />
                <div className="itemTextTogether">
                  <div className="itemHeader">
                    Nov prostor i smeštaj u skladu sa evropskim pet hotelima
                  </div>
                  <div className="itemText">
                    Na raspolaganju imamo 10 vrhunski opremljenih boxova
                    veličine po 5 kvadratinih metara, koji će osigurati vašim
                    ljubimcima udobnost i privatnost tokom njihovog boravka.
                  </div>
                </div>
              </div>
              <div className="textItem">
                <img src={doggie3} alt="doggie2" />
                <div className="itemTextTogether">
                  <div className="itemHeader">
                    Dovoljno mesta za igru i skakanje
                  </div>
                  <div className="itemText">
                    Naš hotel prostire se na impozantnih 320 kvadratnih metara,
                    što pruža vašim ljubimcima dovoljno prostora za igru i
                    druženje sa drugim gostima. Naša pažljivo osmišljena
                    igraonica će ih držati aktivnima i srećnima tokom celog
                    dana.
                  </div>
                </div>
              </div>
              <div className="textItem">
                <img src={doggie1} alt="doggie3" />
                <div className="itemTextTogether">
                  <div className="itemHeader">Poligoni za dresuru i rad</div>
                  <div className="itemText">
                    Za one koji žele dodatnu obuku i socijalizaciju, imamo 2
                    poligona za dresuru gde naši stručni treneri mogu raditi sa
                    vašim ljubimcima.
                  </div>
                </div>
              </div>
              <div className="textItem">
                <img src={doggie4} alt="doggie4" />
                <div className="itemTextTogether">
                  <div className="itemHeader">
                    vrhunski patronažni veterinari dostupni 24/7
                  </div>
                  <div className="itemText">
                    Zdravlje i dobrobit vaših ljubimaca su nam prioritet. Imamo
                    tim patronažnih veterinara koji redovno prate i brinu se o
                    svim našim gostima.
                  </div>
                </div>
              </div>
              <div className="textItem">
                <img src={doggie5} alt="doggie5" />
                <div className="itemTextTogether">
                  <div className="itemHeader">
                    sva neophodna oprema i sadržaji
                  </div>
                  <div className="itemText">
                    Nudimo sve potrebne sadržaje za negu kućnih ljubimaca,
                    uključujući hranu visokog kvaliteta, igračke i udobne
                    krevete.
                  </div>
                </div>
              </div>
              <div className="textNormal">
                Mi volimo životinje i posvećeni smo njihovom blagostanju tokom
                boravka. Ne brinite se o svojim ljubimcima dok ste odsutni -
                Wonderful Pet Hotel će se pobrinuti za njih sa ljubavlju,
                pažnjom i najvišim standardima kvaliteta.;
                <br /> <br />
                <NavLink
                  to="/kontakt"
                  className="contact"
                  style={{ textDecoration: "none" }}
                >
                  <span className="linkSpanT"> Kontaktirajte nas </span>
                </NavLink>
                danas da rezervišete boravak za vašeg ljubimca ili saznate više
                o našim uslugama.
              </div>
            </Fade>
          </div>
          <div className="hotelRight">
            <Fade duration={1000} right>
              <QuestionForm />
            </Fade>
            <Fade duration={1000} bottom>
              <GalerySmall />
            </Fade>
          </div>
        </div>
      </div>
      <Fade duration={1000} bottom>
        <PageBottom />
      </Fade>
    </>
  );
}

export default PageHotel;
