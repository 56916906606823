import React from "react";
import "./StickyBoxes.css";
import igLogo from "../../img/igLogo.png";
import gpsLogo from "../../img/gpsLogo.png";
import ytLogo from "../../img/ytLogo.png";
import fbLogo from "../../img/fbLogo.png";

const StickyBoxes = () => {
	return (
		<div className="stickyContainer">
			<a
				href="https://www.google.com/maps/place/WONDERFUL+PET+HOTEL/@44.9312681,20.2920213,14.25z/data=!4m14!1m7!3m6!1s0x475a5f91cf164717:0xbc49301165e63596!2sWONDERFUL+PET+HOTEL!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm!3m5!1s0x475a5f91cf164717:0xbc49301165e63596!8m2!3d44.9308154!4d20.3053348!16s%2Fg%2F11kpv6vnbm?entry=ttu"
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="stickyBox">
					<img src={gpsLogo} alt="gps" />
				</div>
			</a>
			<a href="https://www.facebook.com/profile.php?id=100093829882436" target="_blank" rel="noopener noreferrer">
				<div className="stickyBox">
					<img src={fbLogo} alt="facebook" />
				</div>
			</a>
			<a href="https://www.instagram.com/wonderful.petcare/" target="_blank" rel="noopener noreferrer">
				<div className="stickyBox">
					<img src={igLogo} alt="instagram" />
				</div>
			</a>
			<a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
				<div className="stickyBox">
					<img src={ytLogo} alt="youtube" />
				</div>
			</a>
		</div>
	);
};

export default StickyBoxes;
