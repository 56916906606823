import React, { useRef, useState, useEffect } from "react";
import "./QuestionForm.css";
import emailjs from "@emailjs/browser";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sr from "date-fns/locale/sr"; // Import Serbian locale
import dogL from "../../img/dogL.png";
import dogR from "../../img/dogR.png";
import clockIcon from "../../img/clockIcon.png";

// Register the Serbian locale with 24-hour time format
registerLocale("sr", {
	options: { hour12: false }, // Set hour12 to false for 24-hour time format
});

// Set the locale globally for all DatePicker instances
DatePicker.defaultProps.locale = sr;

const QuestionForm = () => {
	const form = useRef();
	const [errorMessage, setErrorMessage] = useState("");
	const [isSent, setIsSent] = useState(false);
	const [startDate, setStartDate] = useState(null); // Set initial value to null
	const [endDate, setEndDate] = useState(null); // Set initial value to null
	const [startTime, setStartTime] = useState(null); // Set initial value to null
	const [endTime, setEndTime] = useState(null); // Set initial value to null

	const validateForm = () => {
		let isValid = true;
		form.current.querySelectorAll("input, textarea").forEach((input) => {
			if (!input.value.trim()) {
				input.classList.add("error-border");
				isValid = false;
			} else {
				input.classList.remove("error-border");
			}
		});

		if (!isValid) {
			setErrorMessage("Popunite sva polja");
		} else {
			setErrorMessage("");
		}

		return isValid;
	};

	const formatDate = (date) => {
		if (!date) return "";
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	useEffect(() => {
		// Load and initialize reCAPTCHA script
		const script = document.createElement("script");
		script.src = "https://www.google.com/recaptcha/api.js?render=YOUR_SITE_KEY";
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			// Initialize reCAPTCHA once the script is loaded
			window.grecaptcha.ready(() => {
				// console.log("reCAPTCHA loaded");
			});
		};

		return () => {
			// Clean up: remove the script from the DOM
			document.body.removeChild(script);
		};
	}, []);

	const sendEmail = async (e) => {
		e.preventDefault();

		if (!validateForm()) return;

		// Verify reCAPTCHA v3 token
		const token = await window.grecaptcha.execute("6Lcn-JMpAAAAAE-3c0MO4rnPr37LZr9i0O9_VoX5", { action: "submit" });
		// console.log("reCAPTCHA token:", token);

		// Prepare the data object with formatted values
		const formData = {
			name: form.current.name.value,
			email: form.current.email.value,
			phone: form.current.phone.value,
			dateComing: startDate ? formatDate(startDate) : "", // Check if startDate is not null before formatting
			timeComing: startTime ? startTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }) : "",
			dateLeaving: endDate ? formatDate(endDate) : "", // Check if endDate is not null before formatting
			timeLeaving: endTime ? endTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }) : "",
			msgText: form.current.msgText.value,
			recaptchaToken: token, // Include the reCAPTCHA token in the form data
		};

		// Send the form data via email using emailjs
		emailjs.send("service_12gw8ep", "template_xwsnjwr", formData, "ZiplFHHQRkrzwLJR4").then(
			() => {
				setIsSent(true); // Set isSent to true on success
				console.log("SUCCESS!");
			},
			(error) => {
				console.log("FAILED...", error.text);
			}
		);
	};

	const handleFieldFocus = (e) => {
		const focusedInput = e.target;
		focusedInput.classList.remove("error-border");
	};
	const CustomInput = React.forwardRef(({ value, onClick, placeholderText, name, icon }, ref) => (
		<div className="custom-input">
			<input
				type="text"
				defaultValue={value} // Use defaultValue instead of value
				onClick={onClick}
				placeholder={placeholderText}
				name={name}
				className="rounded-input"
				onFocus={handleFieldFocus}
				ref={ref} // Forward the ref to the input element
			/>
			<img src={icon} alt="Icon" className="custom-icon" />
		</div>
	));

	return (
		<div className="questionnaire-container">
			<div className="headerImg">Pošaljite upit</div>
			<form ref={form} onSubmit={sendEmail}>
				<input type="text" name="name" placeholder="Vaše ime i prezime" className="rounded-input" onFocus={handleFieldFocus} />
				<input type="email" name="email" placeholder="Email adresa" className="rounded-input" onFocus={handleFieldFocus} />
				<input type="tel" name="phone" placeholder="Kontakt telefon" className="rounded-input" onFocus={handleFieldFocus} />
				<div className="datetime-container paddedTop">
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						popperPlacement="top"
						minDate={new Date()} // Set minimum date to today
						customInput={<CustomInput placeholderText="Dolazak" name="dateComing" icon={dogL} />}
					/>
					<DatePicker
						selected={startTime}
						onChange={(date) => setStartTime(date)}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={15}
						timeCaption="Vreme"
						popperPlacement="top"
						dateFormat="HH:mm"
						customInput={<CustomInput placeholderText="Vreme" name="timeComing" icon={clockIcon} />}
					/>
				</div>
				<div className="datetime-container paddedBot">
					<DatePicker
						selected={endDate}
						onChange={(date) => setEndDate(date)}
						popperPlacement="top"
						minDate={new Date()} // Set minimum date to today
						customInput={<CustomInput placeholderText="Odlazak" name="dateLeaving" icon={dogR} />}
					/>
					<DatePicker
						selected={endTime}
						onChange={(date) => setEndTime(date)}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={15}
						timeCaption="Vreme"
						popperPlacement="top"
						dateFormat="HH:mm"
						customInput={<CustomInput placeholderText="Vreme" name="timeComing" icon={clockIcon} />}
					/>
				</div>
				<textarea name="msgText" placeholder="Tekst poruke" className="rounded-input" onFocus={handleFieldFocus} />
				{errorMessage && <span className="error-message">{errorMessage}</span>}
				{isSent && (
					<span className="error-message" style={{ color: "green" }}>
						Poslato!
					</span>
				)}
				<button type="submit">Pošalji upit</button>
			</form>
		</div>
	);
};

export default QuestionForm;
