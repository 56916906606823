import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.css";
import headerLogo from "../../img/headerLogo.png";
import iconSmall from "../../img/IconSmall.png";
import { Fade } from "react-reveal";

function Navbar() {
	const location = useLocation();
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setIsSticky(window.scrollY > 0);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	// Function to determine the active class
	const activeStyle = (path) => {
		return location.pathname === path ? "selected" : "";
	};

	return (
		<div className={`navbar-container ${isSticky ? "sticky" : ""}`}>
			<Fade duration={1000} left>
				<div className="blue-line"></div>
			</Fade>
			<div className="navbar">
				<div className="nav-item">
					<NavLink to="/naslovna">
						<img src={headerLogo} alt="Logo" />
					</NavLink>
				</div>
				<NavLink to="/naslovna" className={`nav-item ${activeStyle("/naslovna")}`}>
					NASLOVNA
					<img src={iconSmall} alt="Icon" className="nav-icon" />
				</NavLink>
				<NavLink to="/hotel-za-pse" className={`nav-item ${activeStyle("/hotel-za-pse")}`}>
					HOTEL ZA PSE
				</NavLink>
				<NavLink to="/pet-taxi" className={`nav-item ${activeStyle("/pet-taxi")}`}>
					PET TAXI
				</NavLink>
				<NavLink to="/galerija" className={`nav-item ${activeStyle("/galerija")}`}>
					GALERIJA
				</NavLink>
				<NavLink to="/cenovnik" className={`nav-item ${activeStyle("/cenovnik")}`}>
					CENOVNIK
				</NavLink>
				<NavLink to="/najcesca-pitanja" className={`nav-item ${activeStyle("/najcesca-pitanja")}`}>
					NAJČEŠĆA PITANJA
				</NavLink>
				<NavLink to="/kontakt" className={`nav-item ${activeStyle("/kontakt")}`}>
					KONTAKT
				</NavLink>
			</div>
			<Fade duration={1000} right>
				<div className="yellow-line"></div>
			</Fade>
		</div>
	);
}

export default Navbar;
